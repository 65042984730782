<!-- 商城商品列表集合-->
<template>
  <div class="grid-container">
    <div v-for="(item, index) in DataList" :key="index" class="grid-item">
      <div style="padding-left: 0.2rem" @click="goDetail(item)">
        <div class="card-item">
          <van-image lazy-load fit="fill" radius="0.5rem"
            style="border-bottom-left-radius: 0; border-bottom-right-radius: 0; width: 100%; height: 11.5rem"
            :src="item.coverImage" />
          <span class="texttitle">
            <van-tag class="tag" v-if="getTitleTag(item)" :color="getTitleTag(item).colorCode">{{ getTitleTag(item).name }}</van-tag>
            {{ item.name }} 
          </span>
          <div class="tags">
            <span class="contextTag" v-for="(val, index) in getTagList(item)" :key="index">{{ val.name }}
            </span>
          </div>
          <div class="textFoot">{{ item.showPrice }}点</div>
        </div>
      </div>
    </div>
  </div>
  <!-- <div class="container-water-fall">
    <waterfall :col='2' :gutterWidth="10" :data="DataList" @loadmore="loadmore" @scroll="scroll">
      <template>
        <div class="cell-item" v-for="(item, index) in DataList">
          <img v-if="item.coverImage" :src="item.coverImage" alt="" />
          <span class="texttitle">
            <van-tag class="tag" v-if="getTitleTag(item)" :color="getTitleTag(item).colorCode">{{
      getTitleTag(item).name }}
            </van-tag>
            {{ item.name }} </span>
          <div class="tags">
            <span class="contextTag" v-for="(val, index) in  getTagList(item)">{{
      val.name }}
            </span>
          </div>
          <div class="textFoot">{{ item.showPrice }}点</div>
        </div>
      </template>
    </waterfall>
  </div> -->
</template>

<script>
export default {
  name: "",
  props: {

    DataList: {
      type: Array,
    },
  },
  data() {
    return {
      active: 0

    };
  },
  components: {},
  methods: {
    scroll(val) {

    },
    loadmore(index) {
      console.log('load more');
    },
    getBackC(index) {
      if (index % 4 == 0) {
        return '#108ee9'
      } else if (index % 3 == 0) {
        return '#87d068'
      } else if (index % 2 == 0) {
        return '#2db7f5'
      } else {
        return '#f50'
      }

    },
    goDetail(val) {
      if(val.type == '7'){
        //跳转直充商品详情
        this.$router.push({
          path: "/DetailsRecharge",
          query: {
            id: val.id,
            specId: val.specId,
            brandId: val.brandId,
          }
        });
      }else{
        this.$router.push({
          path: "/Details",
          query: {
            id: val.id,
            specId: val.specId,
            brandId: val.brandId,
          }
        });
      }
    },
    getTitleTag(item) {
      let Tag = undefined
      if (item.labelList) {
        item.labelList.forEach(element => {
          if (element.smallLabelType == '1') {
            Tag = element
          }
        });
      }
      return Tag
    },
    getTagList(item) {
      let Tag = []
      if (item.labelList) {
        item.labelList.forEach(element => {
          if (element.smallLabelType !== '1') {
            Tag.push(element)
          }
        });
      }
      return Tag
    }

  },

  mounted() {
  },
  computed: {},
  watch: {},
};
</script>

<style lang='scss' >
.container-water-fall {

  /* // padding: 0 28px; */
  padding: 0 3% ;
  width: 100vw;
  box-sizing: border-box;
  /* background: #fafafa !important; */
}

.cell-item {
  background: #ffffff;
  border-radius: 10px;
  overflow: hidden;
  box-sizing: border-box;
  margin: 5% 0;
}

.cell-item img {
  width: 100%;
  height: auto;
  display: block;
}

.grid-container {
  display: flex;
  flex-wrap: wrap;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
  justify-content: space-between;

  .grid-item {
    flex-basis: 49%;
    width: 9.4375rem;
    margin-bottom: 0.5rem;

    .card-item {
      background-color: white;
      border-radius: 0.5rem;
      box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, 0.08);

    }
  }
}

.texttitle {
  font-size: 0.875rem;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  min-height: 2.5rem;
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.tags {
  margin-left: 0.75rem;
  margin-right: 0.55rem;
  margin-top: 0.2rem;

  .tag {
    margin-right: 0.2rem;
  }
}

.textFoot {
  font-size: 1.125rem;
  color: #d23b2e;
  letter-spacing: 0;
  font-weight: 500;
  height: 2rem;
  margin-left: 0.75rem;
  margin-top: 0.25rem;
  margin-bottom: 0.3125rem;
}

.contextTag {
  position: relative;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: inline-flex;
  font-weight: 500;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 0 1.067vw;
  color: #2db7f5;
  padding: 0 1.067vw;
  border: 1px #2db7f5 solid;
  font-size: 3.2vw;
  line-height: 4.2vw;
  border-radius: 0.533vw;
  margin-right: 0.3rem;

}

.van-tag {
  position: relative;
  display: -webkit-inline-box;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  padding: 0 1.067vw;
  color: #fff;
  font-size: 3.2vw;
  line-height: 4.2vw;
  border-radius: 0.533vw;
}
</style>