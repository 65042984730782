import Vue from 'vue';
import VueRouter from 'vue-router';
import { Switch } from 'vant';
import Home from '../views/Home.vue';

Vue.use(VueRouter);

const routes = [
  //福利卡
  {
    path: '/',
    name: 'CardViews',
    component: () => import('../views/cardViews/CardViews'),
    meta: {
      title: '福利卡',
      index: '/CardViews',
    },
  },
  //福利卡
  {
    path: '/AllCardViews',
    name: 'AllCardViews',
    component: () => import('../views/cardViews/AllCardViews'),
    meta: {
      title: '福利卡',
      index: '/AllCardViews',
    },
  },
  //福利卡
  {
    path: '/UserDetail',
    name: 'UserDetail',
    component: () => import('../views/cardViews/UserDetail'),
    meta: {
      title: '福利卡详情',
      index: '/UserDetail',
    },
  },
  //消费记录
  {
    path: '/UsedRecord',
    name: 'UsedRecord',
    component: () => import('../views/cardViews/UsedRecord'),
    meta: {
      title: '消费记录',
      index: '/UsedRecord',
    },
  },
  {
    path: '/HomeNew',
    name: 'HomeNew',
    component: Home,
    meta: {
      title: '首页',
      index: '/',
      keepAlive: true
    },
  },

  {
    path: '/SearchGoods',
    name: 'SearchGoods',
    component: () => import('../views/SearchGoods'),
    meta: {
      title: '商品搜索',
      index: '/',
      keepAlive: true
    },
  },
  {
    path: '/Cashier',
    name: 'Cashier',
    component: () => import('../views/Cashier'),
    meta: {
      title: '收银台',
      keepAlive: true,
    },
  },
  {
    path: '/MovieCashier',
    name: 'MovieCashier',
    component: () => import('../views/MovieCashier'),
    meta: {
      title: '收银台',
      keepAlive: true,
    },
  },
  {
    path: '/MovieForGoodsCashier',
    name: 'MovieForGoodsCashier',
    component: () => import('../views/MovieForGoodsCashier'),
    meta: {
      title: '收银台',
      keepAlive: true,
    },
  },
  // 登陆 注册
  {
    path: '/Login',
    name: 'Login',
    component: () => import('../views/login/Login'),
    meta: {
      title: '登陆',
      index: '/Login',
      keepAlive: true,
    },
  },
  //协议
  {
    path: '/Agreement',
    name: 'Agreement',
    component: () => import('../views/login/Agreement'),
    meta: {
      title: '协议',
      index: '/Agreement',
    },
  },
  // 切换地址

  {
    path: '/SelectAdderss',
    name: 'SelectAdderss',
    component: () => import('../views/swithAdderss/SelectAdderss'),
    meta: {
      title: '城市列表',
      index: '/SelectAdderss',
    },
  },
  // details 详情
  {
    path: '/Details',
    name: 'Details',
    component: () => import('../views/details/Details'),
    meta: {
      title: '详情',
      index: '/Details',
    },
  },
  {
    path: '/DetailsRecharge',
    name: 'DetailsRecharge',
    component: () => import('../views/details/DetailsRecharge'),
    meta: {
      title: '直充商品详情',
      index: '/DetailsRecharge',
    },
  },
  {
    path: '/ZYDetails',
    name: 'ZYDetails',
    component: () => import('../views/details/ZYDetails'),
    meta: {
      title: '详情',
      index: '/ZYDetails',
    },
  },
  //购物车
  {
    path: '/CarViews',
    name: 'CarViews',
    component: () => import('../views/carViews/CarViews'),
    meta: {
      title: '购物车',
      index: '/CarViews',
    },
  },
  // 我的购物车
  {
    path: '/myCart',
    name: 'myCart',
    component: () => import('../views/myCart'),
    meta: {
      title: '购物车',
      index: '/myCart',
    },
  },
  // 结算页面
  {
    path: '/confirmOrder',
    name: 'confirmOrder',
    component: () => import('../views/confirmOrder'),
    meta: {
      title: '确认订单',
      index: '/confirmOrder',
    },
  },
  //福利卡升级
  {
    path: '/UpdateCard',
    name: 'UpdateCard',
    component: () => import('../views/cardViews/UpdateCard'),
    meta: {
      title: '福利卡升级',
      index: '/UpdateCard',
    },
  },

  {
    path: '/BindCard',
    name: 'BindCard',
    component: () => import('../views/cardViews/BindCard'),
    meta: {
      title: '绑定卡券',
      index: '/BindCard',
    },
  },

  //绑卡
  {
    path: '/AddCard',
    name: 'AddCard',
    component: () => import('../views/cardViews/AddCard'),
    meta: {
      title: '绑定新卡',
      index: '/AddCard'
    }
  },
  //
  {
    path: '/EnterpriseBuy',
    name: 'EnterpriseBuy',
    component: () => import('../views/cardViews/EnterpriseBuy'),
    meta: {
      title: '企业采购',
      index: '/EnterpriseBuy'
    }
  },


  // 我的
  {
    path: '/My',
    name: 'My',
    component: () => import('../views/my/My'),
    meta: {
      title: '我的',
      index: '/My',
    }
  },
  // 我的
  {
    path: '/AllMy',
    name: 'AllMy',
    component: () => import('../views/my/AllMy'),
    meta: {
      title: '我的',
      index: '/AllMy',
    }
  },
  // 支付密码
  {
    path: '/SetPassWord',
    name: 'SetPassWord',
    component: () => import('../views/password/SetPassWord'),
    meta: {
      title: '验证身份',
      index: '/SetPassWord',
    }
  },
  // 分类
  {
    path: '/Classification',
    name: 'Classification',
    component: () => import('../views/classification/Classification'),
    meta: {
      title: '分类',
      index: '/Classification',
      keepAlive: true,
    }
  },
  // 电影入口分类
  {
    path: '/MovieClassification',
    name: 'MovieClassification',
    component: () => import('../views/classification/MovieClassification'),
    meta: {
      title: '分类',
      index: '/MovieClassification',
      keepAlive: true,

    }
  },
  // 分类
  {
    path: '/CakaClass',
    name: 'CakaClass',
    component: () => import('../views/classification/CakaClass'),
    meta: {
      title: '分类',
      index: '/CakaClass'

    }
  },
  // 个人资料
  {
    path: '/Personal',
    name: 'Personal',
    component: () => import('../views/my/Personal'),
    meta: {
      title: '个人资料',
      index: '/Personal'

    }
  },
  // 订单支付
  {
    path: '/ToOrder',
    name: 'ToOrder',
    component: () => import('../views/order/ToOrder'),
    meta: {
      title: '订单支付',
      index: '/ToOrder'

    }
  },
  // 订单详情
  {
    path: '/OrderDetail',
    name: 'OrderDetail',
    component: () => import('../views/order/OrderDetail'),
    meta: {
      title: '订单详情',
      index: '/OrderDetail'

    }
  },
  // 订单详情
  {
    path: '/CakaOrderDetail',
    name: 'CakaOrderDetail',
    component: () => import('../views/order/CakaOrderDetail'),
    meta: {
      title: '订单详情',
      index: '/CakaOrderDetail'

    }
  },
  //物流信息
  {
    path: '/Logistics',
    name: 'Logistics',
    component: () => import('../views/logistics/Logistics'),
    meta: {
      title: '物流信息',
      index: '/Logistics'
    }
  },
  // 浏览记录
  {
    path: '/History',
    name: 'History',
    component: () => import('../views/my/History'),
    meta: {
      title: '浏览记录',
      index: '/History'
    }
  },
  // 我的订单
  {
    path: '/AllOrder',
    name: 'AllOrder',
    component: () => import('../views/order/AllOrder'),
    meta: {
      title: '我的订单',
      index: '/AllOrder',
      keepAlive: true
    }
  },
  // 电影订单
  {
    path: '/MovieOrder',
    name: 'MovieOrder',
    component: () => import('../views/order/MovieOrder'),
    meta: {
      title: '电影订单',
      index: '/MovieOrder'
    }
  },
  // 地址列表
  {
    path: '/AddressView',
    name: 'AddressView',
    component: () => import('../views/my/AddressView'),
    meta: {
      title: '地址列表',
      index: '/AddressView'
    }
  },
  // 地址编辑
  {
    path: '/EditAddress',
    name: 'EditAddress',
    component: () => import('../views/address/EditAddress'),
    meta: {
      title: '添加地址',
      index: '/EditAddress'
    }
  },

  // 电影首页
  {
    path: '/MoviesHome',
    name: 'MoviesHome',
    component: () => import('../views/movie/MoviesHome'),
    meta: {
      title: '电影',
      index: '/MoviesHome',
      keepAlive: true
    }
  },
  // 电影看列表加搜索页
  {
    path: '/MoviesList',
    name: 'MoviesList',
    component: () => import('../views/movie/MoviesList'),
    meta: {
      title: '电影',
      index: '/MoviesList',
    }
  },
  // 影院列表搜索
  {
    path: '/CinemasList',
    name: 'CinemasList',
    component: () => import('../views/movie/CinemasList'),
    meta: {
      title: '影院',
      index: '/CinemasList',
      keepAlive: true

    }
  },
  // 电影
  {
    path: '/Movies',
    name: 'Movies',
    component: () => import('../views/movie/Movies'),
    meta: {
      title: '电影',
      index: '/Movies',
    }
  },
  // 影院
  {
    path: '/Cinema',
    name: 'Cinema',
    component: () => import('../views/movie/Cinema'),
    meta: {
      title: '影院',
      index: '/Cinema',
    }
  },
  // 影院详情
  {
    path: '/CinemaDetail',
    name: 'CinemaDetail',
    component: () => import('../views/movie/CinemaDetail'),
    meta: {
      title: '影院详情',
    }
  },
  // 选票
  {
    path: '/Ticket',
    name: 'Ticket',
    component: () => import('../views/movie/Ticket'),
    meta: {
      title: '选票',
    }
  },
  // 电影详情
  {
    path: '/MovieDetail',
    name: 'MovieDetail',
    component: () => import('../views/movie/MovieDetail'),
    meta: {
      title: '电影详情',
    }
  },
  // 电影确认订单
  {
    path: '/ConfigOrder',
    name: 'ConfigOrder',
    component: () => import('../views/movie/ConfigOrder'),
    meta: {
      title: '确认订单',
    }
  },
  // 蛋糕首页
  {
    path: '/CakeList',
    name: 'CakeList',
    component: () => import('../views/cake/CakeList'),
    meta: {
      title: '蛋糕',
      index: '/CakeList',
    }
  },
  // 蛋糕首页
  {
    path: '/CakaHome',
    name: 'CakaHome',
    component: () => import('../views/cake/CakaHome'),
    meta: {
      title: '蛋糕',
      index: '/CakaHome',
      keepAlive: true
    }
  },

  // 商品列表
  {
    path: '/GoodsList',
    name: 'GoodsList',
    component: () => import('../views/details/GoodsList'),
    meta: {
      title: '商品列表',
      index: '/GoodsList',
      keepAlive: true
    },
  },
  // 商品列表(活动)
  {
    path: '/LableList',
    name: 'LableList',
    component: () => import('../views/details/LableList'),
    meta: {
      title: '商品列表',
      index: '/LableList',
      keepAlive: true
    },
  },
  // 商品列表(活动)
  {
    path: '/LableClassList',
    name: 'LableClassList',
    component: () => import('../views/details/LableClassList'),
    meta: {
      title: '商品列表',
      index: '/LableClassList',
      keepAlive: true
    },
  },


  // 商品列表
  {
    path: '/ProductList/:time/:brandName',
    name: 'ProductList',
    component: () => import('../views/details/ProductList'),
    meta: {
      title: '商品列表',
      index: '/ProductList',
      keepAlive: true,
      // refresh:true
    },
  },
  // 三方承载接页面
  {
    path: '/FrameUrl',
    name: 'FrameUrl',
    component: () => import('../views/frame/FrameUrl.vue'),
    meta: {
      // title: '测试',
      index: '/FrameUrl',
    },
  },
  // // 测试
  // {
  //   path: '/text',
  //   name: 'text',
  //   component: () => import('../views/text'),
  //   meta: {
  //     title: '测试',
  //     index: '/text',
  //   },
  // },
  // 404
  {
    path: '*',
    name: '404',
    component: () => import('../views/404'),
    meta: {
      title: '网址错误',
      index: '/404',
    },
  },
  // 签名
  // {
  //   path: '/sign',
  //   name: 'sign',
  //   component: () => import('../views/sign/sign'),
  //   meta: {
  //     title: '签名',
  //     index: '/sign',
  //     keepAlive: true,
  //     // refresh:true
  //   },
  // },
  // 获取用户信息
  // {
  //   path: '/getInfo',
  //   name: 'getInfo',
  //   component: () => import('../views/sign/getInfo'),
  //   meta: {
  //     title: '获取用户信息',
  //     index: '/getInfo',
  //     keepAlive: true,
  //     // refresh:true
  //   },
  // },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  // if (from.meta.keepAlive) {
  //   const $wrapper = document.querySelector('.app-wrapper'); // 列表的外层容器 注意找到滚动的盒子
  //   const scrollTop = $wrapper ? $wrapper.scrollTop : 0;
  //   from.meta.scrollTop = scrollTop;
  // }
  document.title = to.meta.title;
  next();
});

export default router;
